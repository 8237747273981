<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          儲值
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <h1>儲值</h1>
    <hr class="separate-line" />
    <div class="inputMoney">
      <div class="contentWrap">
        <el-form
          ref="form"
          :model="form"
          @submit.prevent.native="submit()"
          :validate-on-rule-change="false"
          label-width="130px"
        >
          <el-form-item required prop="money" label="請輸入儲值金額">
            <div class="inputMoneyWrap">
              <InputMoney
                :money="form.money"
                :currency="form.currency"
                :disabledCurrencySelector="false"
                @changeMoney="
                  money => {
                    form.money = money;
                  }
                "
                @changeCurrency="
                  currency => {
                    form.currency = currency;
                  }
                "
                :rules="commonRules.input"
              />
              <el-button type="primary" @click="submit">
                {{ $t("button.confirm") }}
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import { InputMoney } from "@/components/input";
import formMixin from "@/mixins/form";
import stripeApi from "@/apis/stripe";

export default {
  metaInfo() {
    return {
      title: "Stripe Deposit Information - Ivy-Way Academy"
    };
  },
  components: {
    Breadcrumb,
    InputMoney
  },
  computed: {
    stripePublicKey() {
      if (process.env.NODE_ENV === "development") {
        return "pk_test_51IkrI5BeNhRrry9ZOrs38BJIdM2ALpfpq9nMSFtJeDv7f0M69Ebz934Ux3P2Vpu4SD0dHaor7AT03r1YEfs4P5YZ00LIecAjOE";
      } else {
        return "pk_live_51IkrI5BeNhRrry9ZtlXw6E91qOCU2a2wxMzDII9FZ4ksfhMBtkIVrV5oGvyC6Q58KVkVS2EYWA0uKxyEsGj9xyV30061uUSOaW";
      }
    }
  },
  watch: {},
  mixins: [formMixin],
  data() {
    return {
      form: {
        money: 0,
        currency: "TWD"
      }
    };
  },
  async created() {},
  methods: {
    async submit() {
      const valid = await this.validateForm(this.$refs["form"]);
      if (!valid) {
        return;
      }
      const { session_id } = await stripeApi.createStripeSession(this.form.money, this.form.currency.toLowerCase());
      var stripe = window.Stripe(this.stripePublicKey);
      stripe.redirectToCheckout({ sessionId: session_id });
    }
  }
};
</script>

<style scoped>
.container {
  padding: 20px 0px;
  box-sizing: border-box;
}

.inputMoney {
  display: flex;
  margin: 60px auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 560px);
}

.contentWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inputMoneyWrap {
  display: flex;
}

.inputMoneyWrap > button {
  margin: 0px 8px;
}

.currency {
  margin: 0px 8px;
}
</style>
